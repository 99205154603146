import React, { useState } from "react"
import {
  Box, Flex, Grid, GridItem, Heading, HStack, List, ListItem, SimpleGrid, Switch, Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import { asMoney } from "utilities/strings"
import Link from "components/elements/Link"
import Panel from "components/elements/Panel"
import { Account, Plan } from "sharedTypes"
import { accountPlanPath, useQuery } from "utilities/routes"
import { Redirect } from "react-router-dom"
import { DIOBOX_PRICING_URL } from "utilities/externalLinks"
import { useQueryClient } from "react-query"
import { refreshEvents } from "context/actions"
import Payment from "./Payment"
import UpgradeSuccessful from "../../../modals/UpgradeSuccessful"

type Props = {
  plans: Plan[]
  currentAccount: Account
  onHide: () => void
  defaultSelectedPlan?: string
}

const PlansGrid = ({
  plans, currentAccount, onHide, defaultSelectedPlan,
}: Props) => {
  const [success, setSuccess] = useState(false)

  const { subscription } = currentAccount
  const query = useQuery()
  const type = query.get("plan")
  const currentPlan = plans.find((p) => p.type.toLowerCase() === subscription?.type.toLowerCase())
  const initialPlan = plans.find((p) => p.type.toLowerCase() === type?.toLowerCase())
    || plans.find((p) => p.type.toLowerCase() === defaultSelectedPlan?.toLowerCase())
    || currentPlan
    || plans[0]

  const [selectedPlan, setSelectedPlan] = useState<Plan>(initialPlan)
  const [selectedInterval, setSelectedInterval] = useState<"month" | "year">(subscription?.interval || "year")
  const queryClient = useQueryClient()

  const handlePaymentSuccess = async () => {
    await refreshEvents(queryClient)
    setSuccess(true)
  }

  if (!initialPlan) {
    return <Redirect to={accountPlanPath()} />
  }

  if (success) {
    return <UpgradeSuccessful onClickBack={onHide} />
  }

  return (
    <Box w={1220} m="auto" p={6}>
      {subscription?.legacy && (
      <Panel bgColor="gray.100">
        <Text fontWeight="semibold">Your Current Plan (Legacy {subscription?.type}) is no longer available.</Text>
        See our new plans below.
      </Panel>
      )}
      <Heading my={6} fontSize="2xl" fontWeight="semibold">Choose your plan</Heading>
      <HStack justify="flex-start" fontWeight="semibold" spacing={4}>
        <Text color={selectedInterval === "year" ? "gray.400" : ""}>Monthly Billing</Text>
        <Switch
          isChecked={selectedInterval === "year"}
          onChange={(e) => { setSelectedInterval(e.target.checked ? "year" : "month") }}
        />
        <Text color={selectedInterval === "year" ? "" : "gray.400"}>Annual Billing (Save 40%)</Text>
      </HStack>
      <Flex align="flex-start">
        <Box>
          <Flex align="stretch" my={10}>
            <SimpleGrid mb={8} columns={4} spacing={4}>
              {plans.map((plan) => (
                <PlanButton
                  key={plan.type}
                  plan={plan}
                  annual={selectedInterval === "year"}
                  selected={plan === selectedPlan}
                  onClick={() => setSelectedPlan(plan)}
                />
              ))}
            </SimpleGrid>
          </Flex>
          <Heading fontSize="2xl" fontWeight="semibold" mb={12}>
            Included in all paid plans
          </Heading>
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={8}
          >
            <GridItem>
              <FeatureBlock
                icon="guests"
                title="Event Management"
                features={[
                  "Customizable Registration Forms",
                  "Import and Export Spreadsheets",
                  "Custom Guest Lists",
                  "Live Analytics & Reporting",
                ]}
              />
            </GridItem>
            <GridItem>
              <FeatureBlock
                icon="checkin-multiple-filled"
                title="Check-Ins"
                features={[
                  "Multiple Sessions",
                  "Check-In Alerts",
                  "Badge Printing",
                  "Plus-Ones",
                ]}
              />
            </GridItem>
            <GridItem>
              <FeatureBlock
                icon="ticketing"
                title="Ticket Sales"
                features={[
                  "Process Credit Cards and Cash",
                  "Point-of-Sale Integration",
                  "Credit Card Scanner",
                  "Multiple Currencies",
                ]}
              />
            </GridItem>
            <GridItem>
              <FeatureBlock
                icon="seating"
                title="Seating Charts"
                features={[
                  "Drag & Drop Seating",
                  "Placeholder Seating",
                  "Waiting Lists",
                  "Real-Time Vacancy",
                ]}
              />
            </GridItem>
            <GridItem>
              <FeatureBlock
                icon="dash-calendar"
                title="Event Promotion"
                features={[
                  "Custom Email Invites with RSVP Tracking",
                  "Automatic Guest Confirmations",
                  "High Open and Click Rates",
                  "Homepage with Event Agenda",
                  "Embed Code for External Website Integration",
                ]}
              />
            </GridItem>
            <GridItem>
              <FeatureBlock
                icon="tablet"
                title="Event Apps"
                features={[
                  "iPhone and iPad Apps",
                  "Android App",
                  "QR Code Scanning",
                  "Realtime Sync",
                  "Offline Mode",
                ]}
              />
            </GridItem>
          </Grid>
        </Box>
        <Payment
          selectedPlan={selectedPlan}
          interval={selectedInterval}
          currentAccount={currentAccount}
          onSuccess={handlePaymentSuccess}
        />
      </Flex>
    </Box>
  )
}

const PlanButton = ({
  plan, annual, selected, onClick,
}) => {
  const {
    type, description, features, monthlyPrice, annualPrice,
  } = plan
  const price = annual ? annualPrice / 12 : monthlyPrice

  return (
    <Flex h="full" direction="column" align="center" onClick={onClick} cursor="pointer">
      <Box
        p={4}
        flex={1}
        m={selected ? 0 : "1px"}
        borderWidth={selected ? 2 : 1}
        borderColor={selected ? "blue.500" : "gray.500"}
        bg={selected ? "blue.50" : "white"}
        borderRadius="md"
      >
        <Heading fontSize="2xl" fontWeight="semibold" mb={2}>{type}</Heading>
        <Text fontSize="sm" lineHeight={1.25} mb={3}>{description}</Text>
        <Text fontSize="4xl" lineHeight={1.25}>{asMoney(price, "USD", true)}</Text>
        <Text fontSize="sm" mb={5}>Per Month</Text>
        <List>
          {features.map((feature, index) => <ListItem key={index} fontSize="sm" mb={1}><Icon ml={1} mr={3} size={3} icon="checkmark" color="blue.500" />{feature}</ListItem>)}
        </List>
      </Box>
      <Box h={6} mt={1}>{selected && <Link target="_blank" href={DIOBOX_PRICING_URL}>See all features <Icon icon="next-arrow" /></Link>}</Box>
    </Flex>
  )
}

const Feature = ({ children }) => (
  <ListItem mb={4}><Icon ml={1} mr={3} size={4} icon="checkmark" color="blue.500" />{children}</ListItem>
)

const FeatureBlock = ({ icon, title, features }) => (
  <>
    <Heading fontSize="md" mb={4}><Icon icon={icon} color="blue.500" size={6} mr={2} /> {title}</Heading>
    <List>
      {features.map((feature, index) => <Feature key={index}>{feature}</Feature>)}
    </List>
  </>
)

export default PlansGrid
