import { usePlans } from "queries/plans"
import useCurrentAccount from "services/useCurrentAccount"
import { SubscriptionType } from "sharedTypes"

const useCurrentPlanType = () => {
  const { subscription } = useCurrentAccount()
  const { plans } = usePlans()

  const highestPlan = plans[plans.length - 1]
  const onLargestPlan = subscription?.type === highestPlan?.type
  const corporateOrHigherPlans = plans.slice(
    plans.findIndex((plan) => plan.type === SubscriptionType.Corporate),
  )
  const corporateFeaturesEnabled = corporateOrHigherPlans.some(
    (plan) => plan.type === subscription?.type,
  )

  return {
    corporateFeaturesEnabled,
    onLargestPlan,
  }
}

export default useCurrentPlanType
