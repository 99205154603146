import Modal from "components/dialogs/Modal"
import React from "react"
import { Account } from "sharedTypes"
import PlansGrid from "pages/Account/Plan/PlansGrid"
import { usePlans } from "queries/plans"
import Spinner from "components/elements/Spinner"
import Icon from "components/elements/Icon"

type Props = {
  currentAccount: Account
  onHide: () => void
  defaultSelectedPlan?: string
}

const UpgradePlan = ({ currentAccount, onHide, defaultSelectedPlan }: Props) => {
  const { subscription } = currentAccount
  const { isLoading, plans } = usePlans()
  if (isLoading) { return <Spinner /> }

  const availablePlans = () => {
    if (!subscription?.stripeId) {
      return plans.slice(1)
    }

    const index = Math.max(
      plans.findIndex((plan) => plan.type === subscription?.type && !subscription?.expired), 0,
    )

    return plans.slice(index + 1)
  }

  return (
    <Modal
      title={<Icon size={30} h={8} icon="diobox-logo" color="blue.500" />}
      show
      hide={onHide}
      size="full"
      noPadding
      closeOnEsc={false}
    >
      <PlansGrid
        plans={availablePlans()}
        currentAccount={currentAccount}
        onHide={onHide}
        defaultSelectedPlan={defaultSelectedPlan}
      />
    </Modal>
  )
}

export default UpgradePlan
